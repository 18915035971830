import React from 'react';
import CookieConsent from 'react-cookie-consent';
import {Link} from 'gatsby';

const CookieBar = () => {
  return (
    <CookieConsent buttonText="Got it!">
      This site uses cookies to ensure the best experience -{' '}
      <a href="https://www.sixtyfive02.com/privacy/policy">
        Read our Privacy Policy
      </a>
    </CookieConsent>
  );
};

export default CookieBar;
