import * as React from 'react';

const SectionBG = ({bgimage, children}) => {
  return (
    <div
      className="block py-32 md:py-56 lg:py-80 bg-cover"
      style={{backgroundImage: `url(${bgimage})`}}>
      <div className="w-4/5 lg:w-3/5 mx-auto -my-14 sm:-my-22 md:-my-30 lg:-my-40 text-white">
        {children}
      </div>
    </div>
  );
};

export default SectionBG;
