import * as React from 'react';

const SectionWhite = ({sectionTitle, children}) => {
  return (
    <div className="py-36 sm:py-48 md:py-56 lg:py-80 bg-white">
      <div className="w-auto lg:w-4/5 mx-auto px-6 md:px-14 -my-24 md:-my-30 text-center text-gray-900">

        {children}
      </div>
    </div>
  );
};

export default SectionWhite;
